<!--
 * @Author: lbh
 * @Date: 2021-04-29 15:25:10
 * @LastEditors: lbh
 * @LastEditTime: 2022-07-26 17:31:35
 * @Description: 免費試用
-->
<template>
  <div
    class="freeTrial t-body"
    :style="`min-height:${window.bodyH}px;`"
  >
    <selfHeaderN
      ad="freeTrial"
      :type="window.type"
    />
    <div
      class="s-body"
      :class="window.type=='1000' ? '' : 's-body_1'"
    >
      <selfBackground
        :backH="window.backH"
        title="免費試用"
        :type="1"
        :wtype="window.type"
        :image="getImgPath('trysubbanner.jpg')"
        :bgImage="getImgPath('g5.jpg')"
      />
      <!-- 服務申請 -->
      <div
        class="items"
        :class="window.type != '1000' ? 'items_1' :''"
      >
        <!-- 線條 -->
        <div class="line"></div>
        <!-- 文字 -->
        <div class="big-title">
          <div class="title">外賣服務N天免费試用申請</div>
          <div class="content">*必需填寫</div>
        </div>
      </div>
      <!-- 表單 -->
      <div
        class="items"
        :class="window.type != '1000' ? 'items_1' :''"
        :style="`background-image:url('${getImgPath(`home_bkg-1-1.png`)}')`"
      >
        <div class="left">
          <div class="t">體驗外賣服務</div>
          <div class="z">專人到店免費試用</div>
          <div class="y">在右側表格內填寫聯絡方式與餐廳的簡單資訊，我們會在最快時間內主動聯絡你。</div>
        </div>
        <div class="right">
          <div class="lable">姓名*</div>
          <selfInput
            v-model="form.name"
            :must="true"
            :rules="rules.name"
          />
          <div class="lable">電話號碼*</div>
          <selfInput
            v-model="form.phone"
            :must="true"
            type="tel"
            :rules="rules.phone"
          />
          <div class="lable">電郵地址*</div>
          <selfInput
            v-model="form.emailAddress"
            :must="true"
            type="email"
            :rules="rules.email"
          />
          <div class="lable">公司/餐廳名稱</div>
          <selfInput v-model="form.restaurantName" />
          <div class="lable">餐廳類型</div>
          <el-select
            v-model="form.restaurantType"
            placeholder=""
          >
            <el-option
              v-for="(item,index) in options"
              :key="index"
              :label="item.value"
              :value="item.type"
            >
            </el-option>
          </el-select>
          <div class="lable">查詢詳情（請簡述你的要求*）</div>
          <selfInput
            v-model="form.description"
            :must="true"
            :rules="rules.description"
          />

          <el-button
            type="primary"
            class="pointer-hover"
            @click="submit"
          >馬上申請試用</el-button>
          <div
            class="error-text"
            v-text="errMsg"
            v-if="errMsg"
          ></div>
          <div class="rules">使用本產品代表同意我們的試用協議，請確保填寫資料齊全，以免不能使用</div>
        </div>
      </div>
    </div>
    <selfFooter :type="window.type" />
  </div>
</template>
<script>import selfHeaderN from "@/components/header/headerN";
import selfFooter from "@/components/footer/footer";
import selfBackground from "@/components/commo/background";
import util from "@/js/utils/util";
import selfInput from "@/components/commo/input";
import { addHomeUser } from "@/js/api/api";
export default {
  name: "self-freeTrial",
  components: {
    selfHeaderN,
    selfFooter,
    selfBackground,
    selfInput,
  },
  data () {
    return {
      window: {
        type: '1000',
        bodyH: 0,
        backH: 0
      },
      form: {
        name: '',
        phone: '',
        emailAddress: '',
        restaurantName: '',
        restaurantType: '1',
        description: '',
        source: 1
      },
      rules: {
        name: '',
        email: '',
        phone: '',
        description: ''
      },
      options: [],
      errMsg: ''
    }
  },
  mounted () {
    util.setTitle('免費試用 – 飯糰有限公司');
    // 初始化窗口
    this.initWindow();
    // 監聽 窗口
    window.onresize = () => {
      // 1000 700
      this.initWindow();
    };
    this.options = [
      { value: '中餐', type: '1' },
      { value: '西餐', type: '2' },
      { value: '泰國菜', type: '3' },
      { value: '自助餐', type: '4' }
    ]
  },
  methods: {
    initWindow () {
      let W = util.W(), w = W.w, h = W.h;
      this.window.bodyH = h;
      if (w < 700) {
        this.window.type = '600';
        this.window.backH = w / 0.7;
      } else if (w < 1000) {
        this.window.type = '900';
        this.window.backH = w / 0.7;
      } else {
        this.window.type = '1000';
        this.window.backH = parseInt(w / 2.5);
      }
    },
    getImgPath (t) {
      return util.getImgPath(t);
    },
    // 點擊提交
    submit () {
      let form = this.form;
      if (form.name == '') {
        this.rules.name = new Date().getTime();
        return;
      }
      if (form.phone == '') {
        this.rules.phone = new Date().getTime();
        return;
      } else {
        if (form.phone.length != 8 && form.phone.length != 11) {
          this.rules.phone = new Date().getTime();
          return;
        }
      }
      if (form.emailAddress == '') {
        this.rules.email = new Date().getTime();
        return;
      } else {
        let pattern = /\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
        if (!pattern.test(form.emailAddress)) {
          this.rules.email = new Date().getTime();
          return;
        }
      }
      if (form.description == '') {
        this.rules.description = new Date().getTime();
        return;
      }

      this.addHomeUser();
    },
    // 提交數據
    addHomeUser () {
      addHomeUser(this.form).then(res => {
        if (res.success) {
          this.errMsg = '謝謝,已發送您的資訊';
        } else {
          this.errMsg = '發生錯誤,請檢查您的資料信息';
        }
      }).catch(err => { })
    }
  }
}
</script>
<style lang="less" scoped>
.items_1 {
  flex-direction: column;
  .line {
    width: 100% !important;
  }
  .big-title {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
  }
  .left {
    width: 88% !important;
    padding: 6% !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    .y {
      text-align: center;
    }
  }
  .right {
    width: 100% !important;
  }
}
.items {
  padding: 50px 10% 0;
  display: flex;
  justify-content: space-between;
  background-repeat: no-repeat;
  .line {
    width: calc(50% - 10px);
    background-color: #ddd;
    height: 1px;
    margin-top: 16px;
  }
  .big-title {
    width: calc(50% - 10px);
    .title {
      font-size: 25.5px;
      margin-bottom: 8px;
    }
    .content {
      font-size: 16px;
    }
  }
  .left {
    padding: 5%;
    width: calc(40% - 10px);
    .t,
    .z {
      font-size: 36px;
      font-weight: 400;
    }
    .y {
      font-size: 16px;
      margin-top: 50px;
    }
  }
  .right {
    width: calc(50% - 10px);
    .lable {
      margin: 30px 0 15px 0;
    }
    .rules {
      font-size: 14px;
      margin-top: 20px;
      margin-bottom: 90px;
      line-height: 22px;
    }
    & /deep/ .el-select {
      width: 100%;
      .el-input {
        .el-input__inner {
          background-color: transparent;
        }
        &.is-focus {
          .el-input__inner {
            border-color: #980000;
            background-color: #980000 !important;
            color: #fff !important;
          }
        }
        &:hover {
          .el-input__inner {
            border-color: #980000;
            background-color: #980000;
            color: #fff !important;
          }
        }
      }
    }
    & /deep/ .el-button {
      margin-top: 50px;
      background-color: #980000;
      border-color: rgb(152, 0, 0);
      &:hover {
        background-color: rgba(152, 0, 0, 0.8);
      }
    }
  }
}
.error-text {
  padding: 8px 6px;
  background-color: #fff;
  border: 2px solid #980000;
  color: #980000;
  margin-top: 10px;
}
</style>
<style scoped>
.selected {
  color: #606266;
}
</style>